/**
 * Print input validation classes.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 *
 * @param {Object} input
 * @return {string}
 */
export const validInput = input => {
    if (! input?.dirty) {
        return ''
    }
    
    return (input?.valid ? 'is-valid' : 'is-invalid')
}
