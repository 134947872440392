import { has, pipe, reduce, values } from 'ramda'

/**
 * Whether the form can submit.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 *
 * @param {Object} inputs - The form inputs.
 * @return {boolean}
 */
export const canSubmitForm = inputs => pipe(
    values,
    reduce((previous, current) => {
        if (! has('valid', current)) {
            return previous && canSubmitForm(current)
        }
        
        return previous && current?.valid
    }, true)
)(inputs)