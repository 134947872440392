<template>
    <section :class="pattern" class="container-fluid">
        <div class="container">
            <div class="row">
                <div class="content col-12 d-flex flex-column justify-content-center align-items-center">
                    <slot></slot>
                </div>
            </div>
        </div>
        <locale-picker></locale-picker>
    </section>
</template>

<script>
    import LocalePicker from '@/component/LocalePicker.vue'
    
    export default {
        components: { LocalePicker },
        name: 'AuthLayout',
        props: {
            pattern: String,
        },
    }
</script>

<style lang="scss" scoped>
    @import '../resource/scss/bootstrap';
    
    section {
        &.red {
            background-image: url('../resource/img/red-pattern.png');
        }
        
        &.blue {
            background-image: url('../resource/img/blue-pattern.png');
        }
        
        .content {
            height: 100vh;
            
            @include media-breakpoint-down(md) {
                height: auto;
                min-height: 100vh;
                padding: {
                    bottom: 3rem;
                    top: 3rem;
                }
            }
            
            .alert {
                width: 400px;
                
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
            
            form {
                width: 400px;
                
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
                
                input {
                    width: 100%;
                }
            }
        }
    }
</style>
