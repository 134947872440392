/**
 * Prepare input helper.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 *
 * @param {Object} config - The user config.
 * @return {Object}
 */
export const formInput = config => {
    const defaultConfig = {
        value: '',
        oldValue: '',
        valid: false,
        dirty: false,
        validation: null
    }
    
    return {
        ...defaultConfig,
        ...config
    }
}
