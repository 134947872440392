<template>
    <button :class="'btn btn-' + color + ' fw-bold d-inline-flex justify-content-center align-items-center px-4 py-2'" :disabled="disabled" :type="type">
        <span v-if="fetching" class="spinner-border spinner-border-sm text-white" role="status"></span>
        <span v-else>
            {{ title }}
        </span>
    </button>
</template>

<script>
export default {
    name: 'SubmitButton',
    props: {
        color: {
            type: String,
            default: 'success',
        },
        disabled: Boolean,
        fetching: Boolean,
        title: String,
        type: {
            type: String,
            default: 'submit',
        },
    },
}
</script>

<style lang="scss" scoped>
button {
    height: auto;
    min-height: 42px;
    min-width: 150px;
    text-align: center;
    transition: background 150ms, color 150ms, opacity 150ms;
    width: auto;
    
    &:disabled {
        cursor: not-allowed !important;
    }
}
</style>
