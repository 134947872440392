import { has, ifElse, mapObjIndexed, pipe, prop, reduce, reject, values } from 'ramda'

/**
 * Prepare form data to submit.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 *
 * @param {Object} inputs - The form inputs.
 * @return {Object}
 */
/*export const prepareData = inputs => pipe(reject(input => (
    ! input.dirty ||
    is(Array, input.value)
        ? equals(input.value, input.oldValue)
        : input.value === input?.oldValue
)), map(prop('value')))(inputs)*/

const shouldRejectInput = input => (
    ! input.dirty ||
    ! input.valid
    
    /*! input.dirty ||
    ! input.valid ||
    is(Array, input.value)
        ? equals(input.value, input.oldValue)
        : input.value === input?.oldValue*/
)

export const prepareData = inputs => pipe(
    reject(input =>
        ifElse(
            has('value'),
            shouldRejectInput,
            input => reduce((prev, current) => prev && shouldRejectInput(current), false, values(input))
        )(input)
    ),
    mapObjIndexed(input =>
        ifElse(
            has('value'),
            prop('value'),
            prepareData
        )(input)
    )
)(inputs)
