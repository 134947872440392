<template>
    <ul>
        <li v-for="locale in locales" @click="changeLanguage(locale)" :class="{active: locale === $i18n.locale}">{{ locale.toUpperCase() }}</li>
    </ul>
</template>

<script>

import { EventBus } from '@/main'
import { availableLocales } from '@/locale'

export default {
    data () {
        return {
            locales: availableLocales,
        }
    },
    mounted () {
    },
    methods: {
        changeLanguage (value) {
            const oldValue = this.$i18n.locale
            
            this.$i18n.locale = value
            
            if (oldValue !== value) {
                EventBus.$emit('locale-changed', value)
            }
        },
    },
    name: 'LocalePicker',
    props: {},
}
</script>

<style lang="scss" scoped>
ul {
    display: flex;
    flex-direction: row;
    top: 1rem;
    right: 1rem;
    position: absolute;
    list-style: none;
    gap: 0.5rem;
    z-index: 100;
    
    li {
        background: white;
        border-radius: 50%;
        aspect-ratio: 1;
        //padding: .5rem;
        width: 2.5rem;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        
        &.active {
            background: #ff7070;
            color: white;
        }
    }
}
</style>
